import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import System from '../components/System'

const SystemPage = () => (
    <Layout>
        <SEO title="system:ourSystem" description="system:description" />
        <System />
    </Layout>
)

export default SystemPage
